/* For webkit-based browsers (Chrome, Safari, Edge) */
.custom-scrollbar::-webkit-scrollbar {
    width: 8px; /* Width of the scrollbar */
    height: 8px; /* Height of the scrollbar (for horizontal scrollbar) */
  }
  
  .custom-scrollbar::-webkit-scrollbar-track {
    background-color: #f1f1f1; /* Background color of the scrollbar track */
  }
  
  .custom-scrollbar::-webkit-scrollbar-thumb {
    background-color: #888; /* Scrollbar thumb color */
    border-radius: 10px; /* Rounded corners for scrollbar thumb */
  }
  
  .custom-scrollbar::-webkit-scrollbar-thumb:hover {
    background-color: #555; /* Color of the scrollbar thumb on hover */
  }
  
  /* For Firefox */
  .custom-scrollbar {
    scrollbar-width: thin;
    scrollbar-color: #888 #f1f1f1; /* thumb color, track color */
  }
  