.mainPart{
    display: flex !important;
    text-align: left !important;
    align-items: center !important;
    margin-left: 0px !important;
    justify-content: center !important;
    background-color: transparent !important;
    margin-top: 70px;
}
.add-txt-orderHistory{
    display: flex;
    margin-top: 20px;
    height: 50px;
    gap: 60px;
    
    /* background-color: white; */
}
.dot {
    height: 13px;
    width: 13px;
    background-color:#EA4335;
    margin-top: 5px;
    border-radius: 50%;
    /* display: inline-block; */
}
.container {
    display: flex;
    align-items: center;
}
.Card-price {
    font-family: Roboto;
    font-weight: 600;
}