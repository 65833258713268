.wv-center {
    min-height: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.no-coupons-container {
    height: calc(100vh - 278px);
    flex-direction: column;
}

.no-coupons-container .title {
    font-size: 20px;
    line-height: 28px;
    font-weight: 500;
    text-align: center;
    color: #1a181e;
}

.description {
    font-size: 18px;
    line-height: 28px;
    font-weight: 500;
    text-align: center;
    color: #424242;
}

.m8 {
    margin: 8px;
}

.mb0 {
    margin-bottom: 2;
}

.couponButton {
    justify-content: center;
    color: rgb(255, 255, 255);
    background-color: rgb(137, 199, 74, 1);
    width: 200px;
    height: 37px;
    font-size: 16px;
    font-weight: 600;
    border-radius: 5px;
    border-width: 1px;
    border-style: solid;
    border-color: transparent;
    border-image: initial;
    overflow: hidden;
    outline: none;
    min-height: 14px;

}

.disable-couponButton {
    justify-content: center;
    color: rgb(255, 255, 255);
    background-color: rgb(175, 175, 175);
    width: 150px;
    height: 37px;
    font-size: 16px;
    font-weight: 600;
    border-radius: 5px;
    border-width: 1px;
    border-style: solid;
    border-color: transparent;
    border-image: initial;
    overflow: hidden;
    outline: none;
    min-height: 14px;

}


.section-text-5 {
    font-size: 16px;
    /* line-height: 24px; */
    font-weight: 600;
    padding-left: 10px;
}

.section-text-sub {
    font-size: 14px;
    /* line-height: 24px; */
    font-weight: 400;
    padding-left: 10px;
}

.ListItem {
    display: flex;
    flex-direction: column;
    justify-content: center;
    vertical-align: middle;
}

.Coupon-Container {

    border-radius: 8px;
    box-shadow: 0 2px 6px #1a181e0a;
    background-color: #fff;
}
.CouponType{
    text-align: center;
    font-size: 20px;
    line-height: 24px;
    font-weight: 600;
}
.CouponHeading {
    padding-top: 15px;
    text-align: center;
    font-size: 18px;
    line-height: 24px;
    font-weight: 600;
}
.formDiv{
    padding-top:10px;
    align-items: center;
    justify-content: center;
    text-align: center;
}
.centerDiv{
    padding-top:10px;
    align-items: center;
    justify-content: center;
    text-align: center;
}