.dotTwo {
    height: 13px;
    width: 13px;
    background-color:#17A64F;
  margin-top: 5px;
    border-radius: 50%;
    /* display: inline-block; */
}
.Card-price {
    font-family: Roboto;
    font-weight: 600;
}